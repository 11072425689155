import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { PageHeading } from "~/atoms/PageHeading";
import { PATH } from "../constant/path";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  data: {
    contentfulPrivacy: GatsbyTypes.ContentfulPrivacy;
  };
  pageContext: {
    contentfulId: string;
  };
}
const Privacy: React.FC<Props> = ({ data }) => {
  const { content, title, ogpTitle } = data.contentfulPrivacy;

  return (
    <>
      <Layout title={title} ogpTitle={ogpTitle} breadCrumbItems={[PATH.TOP, { name: title || `` }]}>
        <div className="privacy">
          <PageHeading title={title} />
          <div className="container" dangerouslySetInnerHTML={{ __html: content?.childMarkdownRemark?.html }} />
        </div>
      </Layout>
      <style jsx>{`
        .privacy {
          max-width: 700px;
          margin: 0 auto;
          padding: 0 20px;
        }
        .container {
          margin: 50px 0 100px;
        }
        .container :global(h2) {
          font-size: 20px;
          font-weight: 500;
          margin: 40px 0 10px;
        }
        .container :global(h3) {
          font-size: 18px;
          font-weight: 500;
          margin: 20px 0 10px;
        }
        .container :global(h4) {
          font-size: 16px;
          font-weight: 500;
          padding-left: 30px;
        }
        .container :global(h4 + ul),
        .container :global(h4 + ol),
        .container :global(h4 + p) {
          padding-left: 45px;
        }
        .container :global(ul) {
          margin: 10px 0 0;
        }
        .container :global(ul > li) {
          margin-left: 10px;
        }
        .container :global(ol) {
          margin: 10px 0 0;
        }
        .container :global(ol > li) {
          margin-left: 10px;
        }
        .container :global(table) {
          border-collapse: collapse;
          width: 100%;
          margin: 15px 0;
        }
        .container :global(table th, table td) {
          border: solid 1px ${colors.gray3};
          padding: 10px 15px;
          text-align: left;
        }
        .container :global(table th) {
          background-color: ${colors.defaultBg};
        }
        .container :global(table td) {
          font-size: 13px;
        }
        .container :global(.textAlignRight) {
          text-align: right;
          margin: 30px 0;
        }
        @media (${breakPoints.sp}) {
          .container {
            margin: 30px 0 70px;
          }
        }
      `}</style>
    </>
  );
};

export default Privacy;

export const privacyQuery = graphql`
  query Privacy($contentfulId: String!) {
    contentfulPrivacy(contentful_id: { eq: $contentfulId }) {
      contentful_id
      content {
        childMarkdownRemark {
          html
        }
      }
      ogpDescription {
        ogpDescription
      }
      title
      ogpTitle
      path
    }
  }
`;
